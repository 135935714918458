<template>
  <div class="h-screen">
    <!-- Forgot actions -->
    <div class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-28">
        <div class="absolute top-5 right-5">
          <SelectLangs />
        </div>

        <div class="w-full mx-auto sm:max-w-md mb-7">
          <Brand size="normal" />
        </div>

        <!-- Form -->
        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <Card>
            <template #title>
              <div class="mx-6 pt-4">
                <!-- Form title -->
                <h2 class="text-xl font-semibold">{{ $t("reset") }}</h2>
              </div>
            </template>
            <template #content>
              <div class="flex flex-col w-full">
                <form @submit.prevent="submit" @keydown="form.onKeydown($event)">
                  <!-- Email field -->
                  <div class="form-group">
                    <label for="email" class="form-label">{{ $t("email") }}</label>
                    <InputText v-model="form.email" id="email" name="email" type="email" class="p-inputtext-sm" disabled />
                    <HasError :form="form" field="email" />
                  </div>

                  <!-- Password field -->
                  <div class="form-group">
                    <label for="password" class="form-label">{{ $t("password") }} <LabelRequired /></label>
                    <InputText v-model="form.password" id="password" name="password" type="password" class="p-inputtext-sm" autofocus />
                    <HasError :form="form" field="password" />
                  </div>

                  <!-- Password confirmation field -->
                  <div class="form-group">
                    <label for="password_confirmation" class="form-label">{{ $t("password_confirmation") }} <LabelRequired /></label>
                    <InputText v-model="form.password_confirmation" id="password_confirmation" name="password_confirmation" type="password" class="p-inputtext-sm" />
                    <HasError :form="form" field="password_confirmation" />
                  </div>

                  <!-- Form submit button -->
                  <Button type="submit" :label="$t('newForgot.recover')" :loading="form.busy" iconPos="right" class="p-button-primary p-button-lg shadow w-full" />

                  <!-- Form error general -->
                  <HasError :form="form" field="error" />

                  <!-- Form success message -->
                  <div v-if="form.successful" class="mt-3">
                    <Message severity="success" :closable="false">{{ $t("resetPassword.success") }}</Message>
                  </div>
                </form>

                <!-- Auxiliar actions -->
                <div class="mt-2">
                  <Divider />
                  <div class="flex-center justify-between mt-3">
                    <router-link :to="{ name: 'Login' }">
                      <Button :label="$t('go_to_login')" icon="pi pi-sign-in" iconPos="left" class="p-button-secondary p-button-text" />
                    </router-link>
                    <a href="https://help.setup.baymark.net/es/docs" target="_blank">
                      <Button :label="$t('help')" icon="pi pi-info-circle" iconPos="left" class="p-button-secondary p-button-text" />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Framework components
import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Divider from "primevue/divider"
import Message from "primevue/message"

import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"

// App components
import Brand from "@/components/Brand"
import SelectLangs from "@/components/SelectLangs"
import LabelRequired from "@/components/LabelRequired"

export default {
  name: "Confirm",
  components: {
    // Framework components
    Card,
    Button,
    InputText,
    Divider,
    Message,
    HasError,

    // App components
    Brand,
    SelectLangs,
    LabelRequired
  },
  data() {
    return {
      form: new FormClient({
        token: this.$route.query?.token,
        email: this.$route.query?.email,
        password: "",
        password_confirmation: ""
      })
    }
  },
  methods: {
    submit() {
      this.form.post(ApiRoute.auth.reset)
    }
  }
}
</script>
